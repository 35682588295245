import React from 'react';
import { FaTrash } from 'react-icons/fa';

const ClientesTable = ({ clientes, onDelete }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full w-full table-auto">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left">Nombre</th>
                        <th className="px-4 py-2 text-left">Apellido</th>
                        <th className="px-4 py-2 text-left">Email</th>
                        <th className="px-4 py-2 text-left">Teléfono</th>
                        <th className="px-4 py-2 text-left">Cumpleaños</th>
                        <th className="px-4 py-2 text-left">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {clientes.map((cliente) => (
                        <tr key={cliente._id} className="border-t border-gray-300">
                            <td className="px-4 py-2">{cliente.nombre}</td>
                            <td className="px-4 py-2">{cliente.apellido}</td>
                            <td className="px-4 py-2">{cliente.email}</td>
                            <td className="px-4 py-2">{cliente.telefono || 'N/A'}</td>
                            <td className="px-4 py-2">
                                {cliente.fechaCumpleanos
                                    ? new Date(cliente.fechaCumpleanos).toLocaleDateString()
                                    : 'N/A'}
                            </td>
                            <td className="px-4 py-2">
                                <button
                                    onClick={() => onDelete(cliente._id)}
                                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                >
                                    <FaTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ClientesTable;
