// src/components/clients/DataPreviewTable.jsx
import React, { useState, useEffect } from 'react';

const DataPreviewTable = ({
    data,
    headers,
    headerMappings,
    onDataChange,
    onMappingChange,
    marcas,
    tiendas,
}) => {
    const [editableData, setEditableData] = useState([]);
    const [mappings, setMappings] = useState({});
    const requiredFields = ['nombre', 'apellido', 'email', 'marca', 'tienda']; // Campos requeridos

    useEffect(() => {
        setEditableData(data);
        setMappings(headerMappings);
    }, [data, headerMappings]);

    const handleDataChange = (rowIndex, key, value) => {
        const updatedData = [...editableData];
        updatedData[rowIndex][key] = value;
        setEditableData(updatedData);
        onDataChange(updatedData);
    };

    const handleMappingChange = (header, newMapping) => {
        const updatedMappings = { ...mappings, [header]: newMapping };
        setMappings(updatedMappings);
        onMappingChange(updatedMappings);
    };

    const possibleFields = [
        { value: '', label: 'Ignorar este campo' },
        { value: 'nombre', label: 'Nombre' },
        { value: 'apellido', label: 'Apellido' },
        { value: 'email', label: 'Email' },
        { value: 'telefono', label: 'Teléfono' },
        { value: 'fechaCumpleanos', label: 'Fecha de Cumpleaños' },
        { value: 'geographic_info.country', label: 'País' },
        { value: 'geographic_info.state', label: 'Estado' },
        { value: 'geographic_info.city', label: 'Ciudad' },
        { value: 'geographic_info.postal_code', label: 'Código Postal' },
        // Añade aquí otros campos que existan en tu base de datos
    ];

    return (
        <div className="overflow-x-auto">
            <div className="min-w-full inline-block">
                <table className="min-w-full table-auto">
                    <thead>
                        <tr>
                            {/* Columnas para Marca y Tienda al principio */}
                            <th className="px-4 py-2 border-b text-left bg-gray-100">
                                Marca
                                <div className="text-xs text-blue-500">Campo requerido</div>
                            </th>
                            <th className="px-4 py-2 border-b text-left bg-gray-100">
                                Tienda
                                <div className="text-xs text-blue-500">Campo requerido</div>
                            </th>
                            {headers.map((header, index) => {
                                const isMapped = !!mappings[header];
                                const isRequired = requiredFields.includes(mappings[header]);
                                return (
                                    <th
                                        key={index}
                                        className="px-4 py-2 border-b text-left bg-gray-100"
                                    >
                                        <div className="flex flex-col">
                                            <span className="font-medium">{header}</span>
                                            <select
                                                value={mappings[header]}
                                                onChange={(e) =>
                                                    handleMappingChange(header, e.target.value)
                                                }
                                                className={`mt-1 p-1 border rounded ${isMapped ? '' : 'bg-red-200'
                                                    }`}
                                            >
                                                {possibleFields.map((field) => (
                                                    <option key={field.value} value={field.value}>
                                                        {field.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {!isMapped && (
                                                <div className="text-xs text-red-500">
                                                    Este campo será ignorado
                                                </div>
                                            )}
                                            {isRequired && (
                                                <div className="text-xs text-blue-500">
                                                    Campo requerido
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {editableData.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                            >
                                {/* Celdas para Marca y Tienda al principio */}
                                <td className="px-4 py-2 border-b">
                                    <select
                                        value={row['marca'] || ''}
                                        onChange={(e) =>
                                            handleDataChange(rowIndex, 'marca', e.target.value)
                                        }
                                        className="w-full p-1 text-sm border rounded"
                                    >
                                        {marcas.map((marca) => (
                                            <option key={marca._id} value={marca._id}>
                                                {marca.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="px-4 py-2 border-b">
                                    <select
                                        value={row['tienda'] || ''}
                                        onChange={(e) =>
                                            handleDataChange(rowIndex, 'tienda', e.target.value)
                                        }
                                        className="w-full p-1 text-sm border rounded"
                                    >
                                        {tiendas.map((tienda) => (
                                            <option key={tienda._id} value={tienda._id}>
                                                {tienda.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                {headers.map((header, colIndex) => (
                                    <td key={colIndex} className="px-4 py-2 border-b">
                                        <input
                                            type="text"
                                            className="w-full p-1 text-sm border rounded"
                                            value={row[header] || ''}
                                            onChange={(e) =>
                                                handleDataChange(rowIndex, header, e.target.value)
                                            }
                                            disabled={!mappings[header]} // Deshabilita si no está mapeado
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataPreviewTable;
