// src/components/clients/ClientesUpload.jsx
import React, { useState, useEffect } from 'react';
import { post, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import DataPreviewTable from './DataPreviewTable';

const ClientesUpload = ({ onUploadSuccess }) => {
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [headerMappings, setHeaderMappings] = useState({});
    const [marcas, setMarcas] = useState([]);
    const [tiendas, setTiendas] = useState([]);

    useEffect(() => {
        // Obtener marcas y tiendas del usuario actual
        const fetchData = async () => {
            try {
                const marcasData = await get('marcas');
                setMarcas(marcasData);

                const tiendasData = await get('tiendas');
                setTiendas(tiendasData);
            } catch (error) {
                Swal.fire('Error', 'No se pudieron cargar marcas y tiendas.', 'error');
            }
        };

        fetchData();
    }, []);

    const handleFileChange = async (event) => {
        if (event.target.files[0]) {
            try {
                const formData = new FormData();
                formData.append('file', event.target.files[0]);

                const response = await post('data/preview', formData);
                const { data, headers, headerMappings } = response;

                // Asignar la primera marca y tienda por defecto en cada registro
                const firstMarcaId = marcas.length > 0 ? marcas[0]._id : '';
                const firstTiendaId = tiendas.length > 0 ? tiendas[0]._id : '';

                const dataWithDefaults = data.map((row) => ({
                    ...row,
                    marca: row['marca'] || firstMarcaId,
                    tienda: row['tienda'] || firstTiendaId,
                }));

                setData(dataWithDefaults);
                setHeaders(headers);
                setHeaderMappings(headerMappings);
                Swal.fire(
                    'Previsualización lista',
                    'Revisa los datos antes de confirmar la importación.',
                    'info',
                );
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
            }
        }
    };

    const handleDataChange = (updatedData) => {
        setData(updatedData);
    };

    const handleMappingChange = (updatedMappings) => {
        setHeaderMappings(updatedMappings);
    };

    const handleConfirmImport = async () => {
        try {
            const payload = {
                data,
                mappings: headerMappings,
            };
            console.log('Mappings:', payload.mappings);
            console.log('Payload enviado al backend:', payload); // Añade esta línea
            const response = await post('data/import', payload);
            const { inserted, errors } = response;

            if (errors.length > 0) {
                Swal.fire({
                    title: 'Importación fallida',
                    html: `<pre style="text-align:left;">${errors.join('\n')}</pre>`,
                    icon: 'error',
                });
            } else {
                Swal.fire('Éxito', 'Datos importados correctamente.', 'success');
                onUploadSuccess();
                // Limpiar el estado si es necesario
                setData([]);
                setHeaders([]);
                setHeaderMappings({});
            }
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <div className="my-4">
            <input type="file" onChange={handleFileChange} />

            {data.length > 0 && (
                <>
                    <DataPreviewTable
                        data={data}
                        headers={headers}
                        headerMappings={headerMappings}
                        onDataChange={handleDataChange}
                        onMappingChange={handleMappingChange}
                        marcas={marcas}
                        tiendas={tiendas}
                    />
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mt-4"
                        onClick={handleConfirmImport}
                    >
                        Confirmar Importación
                    </button>
                </>
            )}
        </div>
    );
};

export default ClientesUpload;
