import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FaUsers,
  FaChartLine,
  FaMoneyBillWave,
  FaRetweet,
  FaUserTie,
  FaInfoCircle
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

const StoreStatisticsPage = () => {
  const { tiendaId, marcaId } = useParams();
  const [selectedTiendaId, setSelectedTiendaId] = useState('');
  const [tiendas, setTiendas] = useState([]);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTiendas = async () => {
      try {
        const response = await get('tiendas');
        // Filtrar tiendas por marcaId si se proporciona
        const tiendasFiltradas = marcaId
          ? response.filter(tienda => tienda.marca === marcaId)
          : response;

        setTiendas(tiendasFiltradas);

        if (tiendasFiltradas.length > 0) {
          const tiendaIdToUse = tiendaId || tiendasFiltradas[0]._id;
          setSelectedTiendaId(tiendaIdToUse);

          // Si no hay tiendaId en la URL, redirigir a la primera tienda
          if (!tiendaId) {
            navigate(`/store-statistics/tienda/${tiendaIdToUse}`);
          }
        } else {
          setError('No hay tiendas disponibles para esta marca.');
        }
      } catch (error) {
        console.error('Error fetching tiendas:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al obtener las tiendas. ' + error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchTiendas();
  }, [tiendaId, marcaId, navigate]);

  useEffect(() => {
    if (selectedTiendaId) {
      const fetchStats = async () => {
        try {
          const response = await get(`store-statistics/tienda/${selectedTiendaId}`);
          setStats(response);
        } catch (error) {
          setError('No hay suficientes datos para mostrar estadísticas.');
          console.error('Error fetching statistics:', error);
          Swal.fire({
            title: 'Error',
            text: error.message || 'No hay suficientes datos para mostrar estadísticas.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      };

      fetchStats();
    }
  }, [selectedTiendaId]);

  const handleTiendaChange = (e) => {
    const newTiendaId = e.target.value;
    setSelectedTiendaId(newTiendaId);
    navigate(`/statistics/tienda/${newTiendaId}`);
  };

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  if (!stats || !tiendas.length) {
    return <div className="p-6">Cargando estadísticas...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Estadísticas de Tienda</h1>

        {/* Selector de Tienda */}
        <div className="mb-6">
          <label htmlFor="tienda-selector" className="block text-lg font-medium text-gray-700">
            Selecciona una Tienda:
          </label>
          <select
            id="tienda-selector"
            value={selectedTiendaId}
            onChange={handleTiendaChange}
            className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {tiendas.map((tienda) => (
              <option key={tienda._id} value={tienda._id}>
                {tienda.nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <StatCard icon={<FaUsers />} title="Clientes" value={stats.totalClientes} tooltip="Número total de clientes registrados para esta tienda." />
          <StatCard icon={<FaChartLine />} title="Ventas Totales" value={stats.totalVentas} tooltip="Total de ventas realizadas en esta tienda." />
          <StatCard icon={<FaRetweet />} title="Tasa de Conversión" value={`${stats.conversionRate.toFixed(2)}%`} tooltip="Porcentaje de clientes que realizan al menos una compra." />
          <StatCard icon={<FaMoneyBillWave />} title="Valor Promedio de Transacción" value={stats.averageTransactionValue.toFixed(2)} tooltip="Promedio del valor de cada transacción realizada en la tienda." />
          <StatCard icon={<FaRetweet />} title="Tasa de Redención de Puntos" value={`${stats.redemptionRate.toFixed(2)}%`} tooltip="Porcentaje de puntos acumulados que han sido canjeados por los clientes en esta tienda." />
        </div>
        <Tooltip id="tooltip" />
      </div>
    </div>
  );
};

const StatCard = ({ icon, title, value, tooltip }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer" data-tooltip-id="tooltip" data-tooltip-content={tooltip}>
    <div className="flex items-center mb-4">
      <div className="text-blue-500 text-2xl">{icon}</div>
      <div className="ml-4">
        <span className="text-lg font-semibold text-gray-700">{title}</span>
      </div>
      <FaInfoCircle className="ml-2 text-gray-400" />
    </div>
    <div className="text-2xl font-bold text-gray-900">{value !== undefined ? value : 'No disponible'}</div>
  </div>
);

export default StoreStatisticsPage;
