import React from 'react';
import { useNavigate } from 'react-router-dom';  // Importar useNavigate
import ClientesUpload from '../../components/clients/ClientesUpload';

function ImportClientesPage() {
    const navigate = useNavigate();  // Hook para la navegación

    // Función para manejar el evento de volver
    const handleGoBack = () => {
        navigate('/clientes');  // Ruta de la página de clientes
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md md:ml-64">
            <h1 className="text-4xl font-bold mb-4">Importar Clientes</h1>
            <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mb-4"
                onClick={handleGoBack}
            >
                Volver
            </button>
            <ClientesUpload onUploadSuccess={() => {
                navigate('/clientes'); // Opcional: Redirige a clientes tras una carga exitosa
            }} />
        </div>
    );
}

export default ImportClientesPage;
