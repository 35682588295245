// src/components/common/Sidebar.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHome, faStore, faTags, faChartBar, faPerson } from '@fortawesome/free-solid-svg-icons';
import useAuthStore from '../../store/authStore';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const capitalize = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

const Sidebar = ({ isOpen }) => {
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [firstMarcaId, setFirstMarcaId] = useState('');

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userData = await get(`users/${user.userId}`);
          if (userData.nombre && userData.apellido) {
            setName(capitalize(`${userData.nombre} ${userData.apellido}`));
          }
          const marcas = await get('marcas');
          if (marcas.length > 0) {
            setFirstMarcaId(marcas[0]._id);
          }
        } catch (error) {
          console.error('Failed to fetch user data or marcas:', error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  return (
    <div className={`fixed top-16 md:top-16 left-0 w-64 h-full bg-gray-800 text-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'} transition-transform duration-300 ease-in-out`}>
      <div className="px-4 py-2">
        <div className="text-xl font-bold mt-10">{name}</div>
      </div>
      <nav>
        <ul className="space-y-4 p-4">
          <li>
            <Link to="/dashboard" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faHome} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/marcas" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faTags} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Marcas</span>
            </Link>
          </li>
          <li>
            <Link to="/tiendas" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faStore} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Tiendas</span>
            </Link>
          </li>
          <li>
            <Link to="/clientes" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faPerson} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Clientes</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard-surveys" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faHeart} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Feedback</span>
            </Link>
          </li>
          <li>
            <Link to="/statistics" className="flex items-center justify-center md:justify-start space-x-2 py-2 px-2 hover:bg-gray-700 rounded">
              <FontAwesomeIcon icon={faChartBar} size="lg" />
              <span className={isOpen ? 'block' : 'hidden md:block'}>Estadísticas</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
