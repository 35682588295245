import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClientesSearchAndCreate = ({ onCreate, searchTerm, setSearchTerm }) => {
    const navigate = useNavigate();

    const handleGoToImport = () => {
        navigate('/clientes/importar');
    };

    return (
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
            {/* Input de búsqueda */}
            <input
                type="text"
                placeholder="Buscar cliente"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full md:w-1/2"
            />

            {/* Contenedor para los botones */}
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
                {/* Botón de crear cliente */}
                <button
                    onClick={onCreate}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 w-full md:w-auto"
                >
                    Crear Cliente
                </button>

                {/* Botón de importar clientes */}
                <button
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 w-full md:w-auto"
                    onClick={handleGoToImport}
                >
                    Importar Clientes
                </button>
            </div>
        </div>
    );
};

export default ClientesSearchAndCreate;
